/* Navbar.css */

.navbar {
    position: fixed;
    top: 0;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2rem 0;
    z-index: 1000;
    background-color: black;
}

.navbar .hamburger {
    position: absolute;
    top: 2rem;
    right: 3rem;
    display: none;
    flex-direction: column;
    justify-content: space-between;
    height: 1.6rem;
    z-index: 1001;
    cursor: pointer;
}

.navbar .hamburger .line {
    width: 2rem;
    height: 0.05rem;
    background-color: #e0e0e0;
}

.navbar .nav-links {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 50%;
}

.navbar .nav-links li {
    margin: 0 1rem;
}

.navbar .nav-links li a {
    text-decoration: none;
    color: #e0e0e0;
}

.navbar .nav-links li a:hover,
.navbar .nav-links li a.active {
    color: #f5f5f5;
    cursor: pointer;
}

/* Mobile Styles */
@media only screen and (max-width: 768px) {
    .navbar .hamburger {
        display: flex;
    }

    .navbar {
        background-color: transparent;
    }

    .navbar .nav-links {
        display: none;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 2rem;
        background: rgba(0,0,0,0.9);  /* Slightly transparent background */
    }

    .navbar .nav-links.showMenu {
        display: flex;
        margin-top: 0;
        padding-left: 0;
    }
}
