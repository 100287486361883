.contact-section-main {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 50px 0;
    background-color: #000;
    color: #fff;
    padding-bottom: 40vh;
}

.contact-section-main h2 {
    font-size: 3em;  /* Make the heading bigger */
    margin-bottom: 20px;
    color: #fff;
}

.contact-button {
    color: #fff;
    background-color: #007BFF;
    padding: 20px 40px;  /* Increase padding to make the button bigger */
    font-size: 1.5em;  /* Make the button text bigger */
    text-decoration: none;
    border-radius: 5px;
    transition: background-color 0.3s ease;
}

.contact-button:hover {
    background-color: #0056b3;
}
