.node {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 50vh 0;
    position: relative;
}

.node-circle {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: white;
    z-index: 1;
}

.node-content {
    width: 50%;
    text-align: center;
    color: white;
    padding: 20px;
    display: flex;
    padding-top: 0;
    flex-direction: column;
}

.node-content.left {
    text-align: right;
    padding-right: 30vw;
    align-items: flex-start;
}

.node-content.right {
    text-align: left;
    padding-left: 30vw;
    align-items: flex-start;
}

.node-year {
    font-size: 5vw;
    margin-bottom: 10px;
    margin-top: 0;
}

.node-year.left {
    padding-right: 80px;
}

.node-year.right {
    padding-left: 50px;
}

.node-achievements {
    list-style-type: circle;
    padding: 0;
    text-align: left;
    font-size: 1.5rem;
}

.node-achievements.left {
    padding-right: 50px;
    padding-bottom: 10px;
}

.node-achievements.right {
    padding-left: 50px;
}

.node-subtitle.left {
    padding-right: 50px;
    text-align: left;
}

.node-subtitle.right {
    padding-left: 50px;
    text-align: left;
}

.node-achievements li {
    margin-bottom: 30px;
}

@media screen and (max-width: 768px) {
    .node-content {
        width: 90%;
    }

    .node-content.left, .node-content.right {
        text-align: left;
        padding-right: 10vw;
        padding-left: 10vw;
    }

    .node-year {
        font-size: 3em;
    }

    .node-year.left, .node-year.right, .node-achievements.left, .node-achievements.right, .node-subtitle.left, .node-subtitle.right   {
        padding-right: 0;
        padding-left: 10vw;
    }
    .node-subtitle.left, .node-subtitle.right {
        font-size: 1em;
        font-style: italic;
    }

    .node-achievements {
        font-size: 1.5rem;
    }
    
    .node-circle {
        left: 6.5%; /* adjust this as needed */
        position: absolute;
    }
}
