.contact-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 100px 0;
    background-color: black;
    color: #333;
    min-height: 100vh;
    font-family: Arial, sans-serif;
}

.contact-section h1 {
    font-size: 2.5em;
    margin-bottom: 50px;
    color: #fff;
}

.contact-info {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: 80%;
    max-width: 900px;
    padding: 20px;
    background: #fff;
    color: #333;
    border-radius: 8px;
    box-shadow: 0 10px 20px 0 rgba(0, 0, 0, .1);
    margin-top: 20px;
}

.info-column {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin-bottom: 20px;
}

.contact-section h2 {
    margin-bottom: 10px;
    font-size: 1.5em;
    color: #333;
}

.contact-section p {
    color: #333;
    font-size: 1.1em;
    text-align: center;
}

.social-links {
    display: flex;
    justify-content: center;
    margin-top: 20px;
}

.social-links a {
    color: #333;
    margin: 0 10px;
}

.social-links a:hover {
    color: #0077b5; /* LinkedIn blue */
}


@media screen and (min-width: 768px) {
    .contact-info {
        flex-direction: row;
    }
    
    .info-column {
        width: 45%;
        margin-bottom: 0;
    }
}
