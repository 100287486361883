body {
    background: #000;
}
.project-section {
    min-height: 120vh;
}
.project-card {
    background: #fff;
    border: 3px solid #fff; 
    color: #000;
    margin-bottom: 20px;
    width: 200px;
    padding: 20px;
    box-sizing: border-box;
    border-radius: 10px;
    transition: all 0.3s ease;
    overflow: hidden;
    display: flex;  
    flex-direction: column;  
    justify-content: center;  
    align-items: center;  
}

.project-card h3,
.project-card p,
.project-card a {
    width: 100%;  
    text-align: center;  
    color: #fff;  
}

.project-card p,
.project-card a {
    opacity: 0;
    transition: all 0.3s ease;
}

.project-card:hover {
    background-color: #111;
}

.project-card:hover p,
.project-card:hover a {
    opacity: 1;
}

.project-grid {
    width: 100%;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}

.project-section h2 {
    text-align: center;
    color: #fff;
    padding-bottom: 50px;
}

.project-card h3 {
    overflow-wrap: break-word;
    word-wrap: break-word;
    hyphens: auto;
}

.view-more-button {
    display: block;
    width: 200px;
    height: 40px;
    margin: 50px auto;
    background: #111;
    text-align: center;
    border-radius: 5px;
    color: #fff;
    line-height: 40px;
    text-decoration: none;
}


@media (max-width: 760px) {
    .project-card {
        background-color: #111;
    }
}