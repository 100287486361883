.footer {
    background-color: #000;
    color: #fff;
    padding: 20px 20px;
    text-align: left;
}

.footer p {
    margin: 0;
    text-align: center;
}

@media (max-width: 760px) {
    .footer p {
        font-size: 0.5em;
    }
}