.testimonials-section {
    background-color: #000;
    padding: 100px 0;
    color: #fff;
}

.testimonials-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    width: 70%;
    gap: 50vh;  /* Creates space between the testimonials */
    margin: 0 auto;
}

.testimonial {
    width: 100%;
}

.testimonial.left {
    text-align: left;
}

.testimonial.right {
    text-align: right;
}

.testimonial h3 {
    font-size: 1.5em;
    margin-top: 10px;
}

.testimonial p {
    font-size: 3em;
    font-style: italic;
}

@media screen and (max-width: 600px) {
    .testimonial h3 {
        font-size: 1.2em;
    }

    .testimonial p {
        font-size: 1.2em;
    }
    .testimonials-container {
        gap: 20vh;
    }
}