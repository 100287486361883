/* Landing.css */
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700&family=Montserrat:wght@300;400;700&display=swap');

* {
    box-sizing: border-box;
    padding: 0;
    font-family: 'Lato', sans-serif;
}

.landing {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    padding: 0 50px;
    color: #fff;
    background-color: #000;
}

.landing-content {
    flex: 1;
    padding-right: 50px;
}

.landing-image {
    flex: 1;
    text-align: center;
}

.landing-image img {
    width: 20vw;
    height: auto;
    border-radius: 10%;
}

.h1 {
    font-size: 2.5rem;
    font-weight: bold;
    color: #fff;
}

.profession-text {
    font-size: 1.5rem;
    color: #fff;
}

.short-description {
    color: #fff;
    font-size: 1.2rem;
    margin-top: 20px;
}

.cta-container {
    margin-top: 20px;
}

.cta-btn {
    display: inline-block;
    padding: 10px 20px;
    font-size: 1.2rem;
    color: #000;
    background-color: #fff;
    text-decoration: none;
    transition: background-color 0.3s ease-in-out;
    border-radius: 10px;
}

.cta-btn:hover {
    background-color: #666;
}

.social-links-landing a {
    margin: 0px 10px;
    border-radius: 30px;
}

.social-links-landing a:first-child {
    margin-left: 0;
}

@media screen and (max-width: 768px) {
    .landing {
        flex-direction: column;
        justify-content: flex-start;
    }

    .landing-content {
        padding-right: 0;
        padding-top: 10vh;
    }

    .landing-image {
        text-align: left;  /* Align the image to the left */
        padding: 20px 0;  /* Add some padding to the top and bottom of the image */
    }

    .landing-image img {
        visibility: hidden;
    }
}